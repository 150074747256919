import React, { useEffect, useRef, useState } from "react"
import sequenceLogo from "../assets/sequence-logo.png"
import ethereumLogo from "../assets/gdc/ethereum-logo.png"
import skyweaverLogo from "../assets/gdc/skyweaver-logo.png"
import customMarketplaceAsset from "../assets/gdc/custom-marketplace.png"
import onboardingAssetDesktop from "../assets/gdc/web3-onboarding-desktop.png"
import onboardingAssetMobile from "../assets/gdc/web3-onboarding-mobile.png"
import { Head } from "../components/sections/Head"
import "../styles/index.css"
import "../styles/gdc.css"
import "../styles/utils.css"
import "../styles/typography.css"
import { Footer } from "../components/sections/Footer"
import { Shimmer } from "../components/Shimmer"
import { init } from "../components/Init"

init()

const Purple = ({ children, kind = 1, style }) => (
  <span className={"gdc-purple-gradient-" + kind} style={style}>
    {children}
  </span>
)

const YTEmbed = ({ videoUrl }) => {
  const [playerHeight, setPlayerHeight] = useState(214)
  const iFrameRef = useRef(null)

  useEffect(() => {
    const handleResize = () => {
      if (!iFrameRef.current) return
      const nextPlayerHeight = iFrameRef.current.clientWidth
      if (nextPlayerHeight !== playerHeight) setPlayerHeight(nextPlayerHeight)
    }
    handleResize()

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <div className="rounded-xl overflow-hidden my-6 w-full mx-auto flex items-center justify-center">
      <iframe
        ref={iFrameRef}
        className="w-full"
        style={{ height: playerHeight * (5 / 9) }}
        id="ytplayer"
        type="text/html"
        src={`${videoUrl}?controls=0&playsinline=1`}
        frameborder="0"
      ></iframe>
    </div>
  )
}

export default function Gdc() {
  return (
    <>
      <Head />
      <div className="fixed inset-0" style={{ zIndex: -2 }}>
        <Shimmer offset="0%" />
      </div>
      <main className="mx-auto max-w-screen-xs">
        <figure className="mx-auto pt-8">
          <div style={{ maxWidth: 518 }} className="px-8">
            <img src={sequenceLogo} />
          </div>
          <figcaption className="font-circular text-white text-center text-3xl leading-8 mt-8 mb-10 mx-auto max-w-screen-xxs">
            Sequence makes Web3{" "}
            <Purple kind={1} style={{ marginRight: -10, paddingRight: 10 }}>
              easy
            </Purple>
            , <Purple kind={2}>fun</Purple> and <Purple kind={3}>secure</Purple>{" "}
            for your players
          </figcaption>
        </figure>
        <section className="gdc-section-gradient rounded-3xl mx-4 py-8 px-4">
          <p className="text-white font-circular text-2xl leading-7 max-w-screen-xxs text-center mx-auto px-2">
            Web3 smart wallet + NFT APIs for games and metaverses
          </p>
          <YTEmbed videoUrl="https://www.youtube.com/embed/FeVlhjEr4LQ" />
          <div className="max-w-screen-xxs mx-auto px-2 flex items-center justify-center">
            <img src={ethereumLogo} className="mr-3" />
            <p className="text-white text-xl tracking-tight text-center font-light font-circular">
              Powered by Ethereum networks
            </p>
          </div>
        </section>
        <section className="rounded-3xl mx-4 pt-9 pb-7 px-4 mt-6">
          <p className="text-white font-circular xs:text-3xl text-2xl xs:leading-9 leading-8 max-w-screen-xxs text-center mx-auto px-2 mb-6">
            Create custom
            <br className="xs:hidden block" /> NFT marketplaces
          </p>
          <img src={customMarketplaceAsset} />
        </section>
        <section className="py-8 px-4">
          <p className="text-white font-circular xs:text-3xl text-2xl xs:leading-9 leading-8 max-w-screen-xxs text-center mx-auto px-2 mb-2">
            Sequence solves player onboarding to Web3
          </p>
          <div className="px-4">
            <img className="xs:hidden block" src={onboardingAssetMobile} />
            <img
              className="xs:block hidden transform scale-118"
              src={onboardingAssetDesktop}
            />
          </div>
          <p className="text-lg text-center mt-3 px-4 font-medium">
            And gives your players a seamless experience.
            <br />
            <br />
            Sequence harmonizes friendly UX and simplified payments with
            non-custodial and secure smart contract design.
          </p>
        </section>
        <section className="bg-off-black rounded-3xl mx-4 pt-6 pb-7 px-4 mt-7">
          <p className="text-white font-circular xs:text-3xl text-2xl max-w-screen-xxs text-center mx-auto px-2 mb-4">
            Sequence in Action
          </p>
          <p
            className="text-lg text-center my-3 mx-auto font-medium"
            style={{ maxWidth: 305 }}
          >
            Watch the demo video below, or try the wallet in our game to
            experience Sequence yourself.
          </p>
          <YTEmbed videoUrl="https://www.youtube.com/embed/iLxsX594h1M" />
          <a
            href="https://play.skyweaver.net/?utm_source=GDC&utm_medium=OnlineMedia&utm_campaign=Skyweaver_OpenBeta_Traffic&utm_term=GDCAudience&utm_content=GDCLandingPage"
            target="_blank"
            className="flex items-center justify-center text-white font-semibold bg-blue rounded-xl w-full max-w-screen-xxs mx-auto block py-4 gdc-try-skyweaver-gradient hover:opacity-50"
          >
            Try Skyweaver Now
          </a>
        </section>
        <section className="rounded-3xl gdc-section-gradient mx-4 mt-8 py-8">
          <p className="font-circular text-white xs:text-3xl text-2xl text-center">
            Come to our talk
          </p>
          <img
            src={skyweaverLogo}
            className="mx-auto my-6 rounded-full shadow"
          />
          <p
            className="text-xl font-semibold text-center text-white mx-auto px-4"
            style={{ maxWidth: 315 }}
          >
            SKYWEAVER: Learnings from Launching a Trading Card Game on Web3
          </p>
          <p className="text-white font-light text-center mt-4">
            Room 209, South Hall
            <br />
            Thursday March 24, 2022
            <br />
            2:00–2:30 pm
          </p>
        </section>
        <section className="max-w-screen-xs mx-auto xs:mb-20 mb-11 px-4">
          <p className="text-center mt-9 xs:text-3xl text-2xl text-white font-circular">
            Have questions?
          </p>
          <p className="text-center my-4 font-medium">
            Come talk to one of our devs at:
          </p>
          <p className="text-white rounded-2xl font-circular text-center text-4xl w-full bg-off-black py-4 gdc-booth-gradient">
            Booth P1626
          </p>
        </section>
      </main>
      <Footer />
    </>
  )
}
